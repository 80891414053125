<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          <v-tabs v-model="model" centered background-color="transparent" show-arrows>
            <v-tab :to="{ name: 'overview' }">{{ $t("hr.account.overview") }}</v-tab>
            <v-tab :to="{ name: 'profile' }">{{ $t("hr.account.profile") }}</v-tab>
            <v-tab :to="{ name: 'language_role' }">{{ $t("hr.account.language_role") }}</v-tab>
            <v-tab :to="{ name: 'contact' }">{{ $t("hr.account.contact") }}</v-tab>
            <v-tab :to="{ name: 'personal' }">{{ $t("hr.account.personal") }}</v-tab>
            <v-tab :to="{ name: 'family' }">{{ $t("hr.account.family") }}</v-tab>
            <v-tab :to="{ name: 'education' }">{{ $t("hr.account.education") }}</v-tab>
            <v-tab :to="{ name: 'employment' }">{{ $t("hr.account.employment") }}</v-tab>
            <v-tab :to="{ name: 'financial' }">{{ $t("hr.account.financial") }}</v-tab>
            <v-tab :to="{ name: 'social_media' }">{{ $t("hr.account.social_media") }}</v-tab>
            <v-tab :to="{ name: 'body_size' }">{{ $t("hr.account.body_size") }}</v-tab>
            <v-tab :to="{ name: 'mobile_device' }">{{ $t("hr.account.mobile_device") }}</v-tab>
            <v-tab :to="{ name: 'managers' }">{{ $t("hr.account.management") }}</v-tab>
          </v-tabs>
        </v-card-title>
        <router-view :profile-settings="profileSettings" />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description index Component Logic
   * @date 28.07.2020
   */
  import { ORGANIZATION_PROFILE_SETTINGS } from "../query";

  export default {
    name: "HumanResources",
    data: () => ({
      model: 0,
      profileSettings: {}
    }),
    methods: {
      fetchOrganizationProfileSettings() {
        this.$apollo
          .query({
            query: ORGANIZATION_PROFILE_SETTINGS,
            fetchPolicy: "no-cache"
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              const profileSettings = {};
              data.organizationDetail.profileSettingsInfo.forEach(info => (profileSettings[info] = false));
              data.organizationDetail.profileSettings.forEach(
                ({ category, editActive }) => (profileSettings[category] = editActive)
              );
              this.profileSettings = profileSettings;
            }
          })
          .catch(error => this.$helpers.showNotification(error.message));
      }
    },
    created() {
      this.fetchOrganizationProfileSettings();
    }
  };
</script>

<style scoped></style>
